<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute -->
  <ion-page><cyan-page-wrapper
    backRoute="/login"
    title="Recuperar contraseña"
    :isForm="true"
  >
    <cyan-form
      header="RECUPERAR CONTRASEÑA"
      subtitle="Introduzca su DUI y recibirá un código SMS para crear o recuperar su
        contraseña."
    >
      <ion-item lines="none">
        <ion-label position="stacked">DUI</ion-label>

        <div class="inputWrapper">
          <ion-input autofocus="true" v-model="storedDui" placeholder="12345678-9" class="__twoFactorStart-DUI"></ion-input>
          <div class="validationFailed"> <ion-icon :icon="warningOutline"></ion-icon> </div>
        </div>
      </ion-item>

      <ion-button class="__twoFactorStart-next" size="large" expand="block" @click="requestCode" tabindex="0">
        <span class="button-right-wrapper">Solicitar código</span>
        <ion-icon slot="end" :icon="arrowForwardCircleOutline"></ion-icon>
      </ion-button>

      <ion-button
        class="dimButton" expand="block"
        @click="$router.replace('/twoFactorConfirm')"
      >
        <span class="button-right-wrapper">Ya tengo un código</span>
        <ion-icon slot="end" :icon="arrowForwardCircleOutline"></ion-icon>
      </ion-button>
      <ion-button
        class="dimButton mobileShowBlock" expand="block"
        @click="$router.replace('/login')"
      >
        <span class="button-right-wrapper">Volver</span>
        <ion-icon slot="end" :icon="arrowBackCircleOutline"></ion-icon>
      </ion-button>
    </cyan-form>
  </cyan-page-wrapper></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, {
  router,
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";
import cyanRequest from "@/modules/cyanRequest";
import CyanForm from "@/components/CyanForm.vue";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonPage,
  IonInput,
  IonIcon
} from "@ionic/vue";
import {
  arrowForwardCircleOutline,
  arrowBackCircleOutline,
  warningOutline,
} from "ionicons/icons";
import ValidatorSet, { validateDUILogin } from "@/modules/cyanValidator";

export default defineComponent({
  name: "TwoFactorStart",
  components: {
    CyanPageWrapper,
    CyanForm,
    IonButton,
    IonIcon,
    IonItem,
    IonPage,
    IonLabel,
    IonInput,
  },
  setup() {
    return { arrowForwardCircleOutline, arrowBackCircleOutline, warningOutline };
  },

  computed: {
    storedDui: {
      get() {
        return store.getters.smsRequestUser;
      },
      set(value: string) {
        store.commit("requestedSMSCode", value);
      },
    },
  },
  data() {
    return {
      validator: null as any,
    };
  },
  mounted() {
    this.validator = new ValidatorSet([
      {
        validator: validateDUILogin,
        element: ".__twoFactorStart-DUI",
        nextElement: ".__twoFactorStart-next",
      },
    ]);
    document
      .querySelector(".__twoFactorStart-next")
      ?.addEventListener("keyup", (ev: any) => {
        if (ev.key == "Enter" || ev.key == " ") this.requestCode();
      });
  },

  methods: {
    async requestCode() {
      if (this.validator) {
        if (!await this.validator.checkWithAlert()) return;
      }

      const d = await cyanRequest("sendCode", {
        isModal: true,
        isPost: true,
        params: {
          name: this.storedDui,
        },
      });

      if (!d.ok) return;

      router.replace("/twoFactorConfirm");
    },
  },
});
</script>

<style scoped>
</style>