
import CyanPageWrapper, {
  router,
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";
import cyanRequest from "@/modules/cyanRequest";
import CyanForm from "@/components/CyanForm.vue";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonPage,
  IonInput,
  IonIcon
} from "@ionic/vue";
import {
  arrowForwardCircleOutline,
  arrowBackCircleOutline,
  warningOutline,
} from "ionicons/icons";
import ValidatorSet, { validateDUILogin } from "@/modules/cyanValidator";

export default defineComponent({
  name: "TwoFactorStart",
  components: {
    CyanPageWrapper,
    CyanForm,
    IonButton,
    IonIcon,
    IonItem,
    IonPage,
    IonLabel,
    IonInput,
  },
  setup() {
    return { arrowForwardCircleOutline, arrowBackCircleOutline, warningOutline };
  },

  computed: {
    storedDui: {
      get() {
        return store.getters.smsRequestUser;
      },
      set(value: string) {
        store.commit("requestedSMSCode", value);
      },
    },
  },
  data() {
    return {
      validator: null as any,
    };
  },
  mounted() {
    this.validator = new ValidatorSet([
      {
        validator: validateDUILogin,
        element: ".__twoFactorStart-DUI",
        nextElement: ".__twoFactorStart-next",
      },
    ]);
    document
      .querySelector(".__twoFactorStart-next")
      ?.addEventListener("keyup", (ev: any) => {
        if (ev.key == "Enter" || ev.key == " ") this.requestCode();
      });
  },

  methods: {
    async requestCode() {
      if (this.validator) {
        if (!await this.validator.checkWithAlert()) return;
      }

      const d = await cyanRequest("sendCode", {
        isModal: true,
        isPost: true,
        params: {
          name: this.storedDui,
        },
      });

      if (!d.ok) return;

      router.replace("/twoFactorConfirm");
    },
  },
});
